"use client";

import { Center } from "@mantine/core";
import KiwiLoader from "~/components/KiwiLoader";

export default function Loading() {
  return (
    <Center mt="xl">
      <KiwiLoader />
    </Center>
  );
}
